import { render, staticRenderFns } from "./LegoAdjuntos.vue?vue&type=template&id=74634e70&scoped=true"
import script from "./LegoAdjuntos.vue?vue&type=script&lang=js"
export * from "./LegoAdjuntos.vue?vue&type=script&lang=js"
import style0 from "./LegoAdjuntos.vue?vue&type=style&index=0&id=74634e70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74634e70",
  null
  
)

export default component.exports