<script>
import LegoModalLayout from '@/views/Helexium/Lego/LegoModalLayout.vue'
import LegoAuditoria from '@/components/Lego/LegoAuditoria.vue'
import LegoAdjuntos from '@/components/Lego/LegoAdjuntos.vue'

export default {
  components: {
    LegoModalLayout,
    LegoAuditoria,
    LegoAdjuntos
  },
  props: {
    process: {
      type: String,
      required: true
    },
    cardId: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.$store.dispatch('lego/getAndSetCard', this.cardId)
  },
  computed: {
    processLowercase() {
      return this.process.toLowerCase()
    }
  }
}

</script>

<template>
  <LegoModalLayout :title='process'>
    <LegoAuditoria :cardId='cardId' v-if="processLowercase == 'validar'" />
    <LegoAdjuntos :cardId='cardId' v-else-if="processLowercase == 'adjuntos'"  />
  </LegoModalLayout>
</template>