<script>
import LegoBtn from '@/components/Lego/LegoBtn.vue'
import AddBtn from '@/components/buttons/AddBtn.vue'
import LegoDropzone from '@/components/Lego/LegoDropzone.vue'
import lodash from 'lodash'

export default {
  props: {
    cardId: {
      type: String,
      required: false
    }
  },
  components: {
    LegoBtn,
    AddBtn,
    LegoDropzone
  },
  computed: {
    card() {
      return this.$store.state.lego.card
    },
    archivos() {
      return this.card.attachedFiles
    },
    filterArchivos() {
      return this.archivos
        .filter(archivo => archivo.name.includes(this.filterIncludes) &&
          (this.filterSelected ? archivo.typeFile === this.filterSelected : true))
    },
    pathNewAttachmentFile() {
      return `Lego/Tablero/${this.$route.params.boardId}/card/${this.cardId}/adjuntos`
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    showEmpty() {
      if (this.archivos) {
        if (this.archivos.length) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    optionsTypeFile() {
      if (this.archivos) {
        const aux = this.archivos.map(file => file.typeFile)
        return lodash.uniq(aux)
      } else {
        return []
      }
    }

  },
  data() {
    return {
      captureDataIsActive: false,
      nameNewAttachedFile: '',
      filterSelected: '',
      filterIncludes: ''
    }
  },
  mounted() {

  },
  methods: {
    async callbackAfterSubmitNewFile(self) {
      const file = {
        legoCardId: this.cardId,
        name: this.nameNewAttachedFile,
        createdById: this.userLoggedIn.id,
        PathFile: self.pathAfterSaveInRemote,
        typeFile: self.dotExtension
      }

      await this.$store.dispatch('lego/addAttachedFile', file)


    },
    cancelNewAttachedFile(onCancel) {
      this.captureDataIsActive = false
      onCancel()
    },
    stylesBackgroundColorByFile( typeFile ) {
      switch (typeFile) {
        case '.pdf':
          return {
            backgroundColor: '#d32f2f'
          }
        case '.docx':
          return {
            backgroundColor: '#185ABD'
          }
        case '.xlsx':
          return {
            backgroundColor: '#115C34'
          }
        case '.pptx':
          return {
            backgroundColor: '#C7471A'
          }
        default:
          return {
            
            backgroundColor: '#0f0f0f'
            
          }
      }
    }
  }
}

</script>
<template>
  <article>
    <div>
      <p class="head-resumen">Archivos asociados a la tarea "{{ card.title }}"</p>
    </div>

    <div class="new-file" :class="{ active: captureDataIsActive }">

      <LegoBtn @click="captureDataIsActive = true" v-if="!captureDataIsActive">
        <AddBtn /> <span>Nuevo archivo</span>
      </LegoBtn>
      <div v-else>
        <LegoDropzone class="wrapper-dropzone" :renameFile="nameNewAttachedFile" @rename="nameNewAttachedFile = $event"
          :callbackAfterSubmit="callbackAfterSubmitNewFile" :remotePath="pathNewAttachmentFile">
          <div class="inputs">
            <div>
              <input type="text" class="lego-input" v-model="nameNewAttachedFile"
                placeholder="Nombre del nuevo documento">
            </div>
          </div>
          <template #btn-cancel="{ onCancel }">
            <vs-button class="slot-button" @click.prevent="cancelNewAttachedFile(onCancel)"  
              danger>Cancelar</vs-button>
          </template>
        </LegoDropzone>
      </div>


    </div>

    <div class="wrapper-section-all-files">
      <header>
        <p>
          Todos los archivos
        </p>
        <div class="filtros">
          <input v-model="filterIncludes" type="text" class="lego-input" placeholder="Por nombre">
          <v-select v-model="filterSelected" class="lego" :options="optionsTypeFile" placeholder="Por tipo" />
        </div>
      </header>
      <transition-group name="list" tag="div" class="archivos">
        <div class="wrapper-file" v-if="showEmpty">
          <div class="wrapper-file--info">
            No existen archivos asociados a esta tarea
          </div>
        </div>

        <div class="wrapper-file" v-for="file in filterArchivos" :key="file.id">
          <a :href="file.pathFile" target="_blank" class="wrapper-file--link">
            <i class="fas fa-link"></i>
          </a>
          <div class="wrapper-file--info" :style="stylesBackgroundColorByFile(file.typeFile)">
            {{ file.name + file.typeFile }}
          </div>
        </div>
      </transition-group>

    </div>
  </article>
</template>
<style scoped>
.wrapper-dropzone :deep(.body) {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: .5rem;
}

.wrapper-dropzone :deep(.dropzone) {
  order: 1;
  height: 38px;
  width: 150px;
}

.wrapper-dropzone :deep(.inputs) {
  order: 2;
}

.wrapper-section-all-files header {
  margin-bottom: 1rem;
}

.filtros {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.archivos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;

}

.wrapper-file {
  height: 150px;
  width: 150px;
  background-color: var(--lego-dbg);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.wrapper-file--info {
  text-align: start;
  /* color: var(--lego-gris); */
  padding: 0.5rem;
  width: 100%;
  text-wrap: wrap;
  overflow: hidden;
  /* background-color: red; */
}

.wrapper-file--link {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--lego-gris);
  padding: .5rem;
}

.new-file {
  margin-bottom: 2rem;
  transition: all 0.5s;
  height: 40px;
  /* min-height: 100px; */
}

.new-file.active {
  height: 90px;

}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all .5s;
}

.list-enter,
.list-leave-to
{
  opacity: 0;
  transform: translateY(30px);
}</style>