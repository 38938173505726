<script>
import LegoQuill from '@/components/Lego/LegoQuill.vue'
import moment from "moment-timezone";

export default {
  components: {
    LegoQuill
  },
  props: {
    cardId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActiveReasignarFecha: false,
      newDueDate: null,
      commentHtml: '',
      options: [
        { label: 'Aceptar entrega', value: 1 },
        { label: 'Rechazar entrega', value: -1 }
      ],
      result: null,
      waiting: false,
      autoWrite: true
    }
  },
  async mounted() {
    await this.$store.dispatch('lego/getAndSetCard', this.cardId)

    this.$watch( () => this.result, this.resultActions)
    this.$watch( () => this.newDueDate, this.newDateActions)
  },
  computed: {
    card() {
      return this.$store.state.lego.card
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    auditoriaComments() {
      const c = this.$store.state.lego.card.comments
      if( c == undefined ) return []

      return this.$store.state.lego.card.comments.filter( comment => comment.isValidate == true )
    }
  },
  methods: {
    restablecer() {
      this.result = null
      this.isActiveReasignarFecha = false
      this.newDueDate = null
      this.commentHtml = ''
    },
    handleCheck(value) {
      this.isActiveReasignarFecha = value
      this.newDueDate = moment(this.card.dueDate).tz('America/Bogota').format('YYYY-MM-DD')
      if (!value) this.newDueDate = null
    },
    async saveValidation() {
      const card = {
        id: this.cardId,
        isValidate: true,
        validateBy: this.userLoggedIn.id,
        acceptTask: this.result == -1 ? false : true,
        dueDate: this.newDueDate ? this.newDueDate : this.card.dueDate,
        description: this.commentHtml, //Se pasara como descripcion pero se debe crear un comentario apartir de esta descripcion
      }

      this.waiting = true
      await this.$store.dispatch('lego/setValidationCard', card)
      this.waiting = false

      this.$router.back()
    },

    resultActions (newValue){
      if( this.autoWrite ) {
        this.commentHtml = this.commentHtml.replace(/<p>La tarea fue (aceptada|rechazada).<\/p>/, '')
        
        if( this.result == null ) return 

        this.commentHtml += `
        <p>
          La tarea fue ${newValue == 1 ? 'aceptada' : 'rechazada'}.
        </p>
        `
      }
    },

    newDateActions() {
      if( this.autoWrite ) {
        this.commentHtml = this.commentHtml.replace(/<p>La fecha de cierre programada para el \d{2}\/\d{2}\/\d{4} fue reasignado para el \d{2}\/\d{2}\/\d{4}\.<\/p>/, '')
        
        if( this.newDueDate == null ) return 

        this.commentHtml += `
        <p >
          La fecha de cierre programada para el ${
            moment(this.card.dueDate).tz('America/Bogota').format('DD/MM/YYYY')
          } fue reasignado para el ${
            moment(this.newDueDate).tz('America/Bogota').format('DD/MM/YYYY')}.
        </p>
        `
      }
    }
  }
}

</script>

<template>
  <div class="body">
    <div class="head-resumen">
      <p >Proceso de validación para la tarea "{{ card.title }}"</p>
      <p v-if="card.isValidate != null">Esta tarea ya fue validada y se concluyo como {{ card.acceptTask ? 'Aceptada' : 'Rechazada' }}</p>
      
    </div>

    <form class="form" @submit.prevent="saveValidation">
      <div>
        <label for="result">Respuesta</label>
        <v-select :reduce="(obj) => obj.value" class="lego" :options="options" v-model="result">
          <template #search="{ attributes, events }">
            <input id="result" class="vs__search" :required="!result" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </div>

      <div>
        <label for="isActiveReasignarFecha">Cambiar fecha de cierre</label>
        <div class="fecha">
          <vs-checkbox id="isActiveReasignarFecha" :value="isActiveReasignarFecha" @input="handleCheck" />
          <input v-model="newDueDate" v-if="isActiveReasignarFecha" type="date" class="lego-input" required />
        </div>
      </div>
      <div>
        <label for="commentHtml">Comentario</label>
        <LegoQuill v-model="commentHtml">
        </LegoQuill>
        <div class="help-write">
          <label for="helpWrite">Asistencia de escritura</label>
          <vs-checkbox id="helpWrite" v-model="autoWrite"></vs-checkbox>
        </div>
      </div>

      <div class="buttons">
        <vs-button   :loading="waiting">Validar</vs-button>
        <vs-button   danger @click.prevent="restablecer">Restablecer</vs-button>
      </div>
    </form>
  </div>
</template>


<style scoped>
.head-resumen {
  border-bottom: 1px solid #585961;
  /* padding: 0.25rem; */
  margin: 1rem 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form>div>label {
  font-weight: bold;
  ;
}

.fecha {
  display: flex;
  gap: 1rem;
  min-height: 40px;
  align-items: center;
}

.help-write {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 0 0;
  gap: 1rem;
}

.help-write>label {
  margin: 0;
}

#helpWrite {
  height: 22px;
  width: 22px;
}
</style>